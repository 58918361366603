import { toZonedTime } from 'date-fns-tz';
import { differenceInCalendarDays } from 'date-fns/differenceInCalendarDays';
import { differenceInMinutes } from 'date-fns/differenceInMinutes';
import { format } from 'date-fns/format';
import { formatDistanceStrict } from 'date-fns/formatDistanceStrict';
import { intervalToDuration } from 'date-fns/intervalToDuration';
import { ru } from 'date-fns/locale/ru';
import { parseISO } from 'date-fns/parseISO';
import { secondsToHours } from 'date-fns/secondsToHours';
import { secondsToMinutes } from 'date-fns/secondsToMinutes';
import React from 'react';
import { FC } from 'react';
import { connect } from 'react-redux';
import { IProfileUser } from '../app/Profile/profile.resources';
import { IReduxStore } from '../store/serviceReducer';
import Icon from './Icon';

interface IProps {
  secondTime?: number | string;
  date?: Date | string | undefined | null;
  startDate?: any;
  endDate?: Date | string | undefined | null;
  children: any;
  combineInfinities?: boolean;
  user: IProfileUser;
}

const LocationDate: FC<IProps> = ({
  date = null,
  children,
  startDate,
  endDate,
  secondTime,
  combineInfinities = true,
  user,
}): any => {
  const validDate = date as any;

  const onTimeZoneParse = (date) => {
    const initialTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const dateTest = date && parseISO(date);

    const timeZone = user?.timestamp ?? initialTimezone;

    return date && toZonedTime(dateTest, timeZone);
  };

  const onParseDateISO = (value) => value && onTimeZoneParse(value);

  const onParseDateFormatISO = (formatData = 'dd.MM.yyyy', defaultValue = validDate) => {
    const parseIsoDate = onParseDateISO(defaultValue);

    return parseIsoDate && format(parseIsoDate, formatData);
  };

  const onParseDateFormatAgo = (defaultValue = validDate) => {
    const formationDate = onParseDateISO(defaultValue);

    const parseDateFormatAgo =
      formationDate &&
      formatDistanceStrict(formationDate, Date.now(), {
        locale: ru,
        addSuffix: true,
      });

    return parseDateFormatAgo || null;
  };

  const onDifferenceInBusinessDays = () => {
    const startDateISO = onParseDateISO(startDate);

    const endDateISO = onParseDateISO(endDate);

    return differenceInCalendarDays(startDateISO, endDateISO);
  };

  const getStartDate = (formatData = 'dd.MM.yyyy') => {
    return onParseDateFormatISO(formatData, startDate);
  };

  const getEndDate = (formatData = 'dd.MM.yyyy') => {
    return onParseDateFormatISO(formatData, endDate);
  };

  const convertDurationToTime = (time) => {
    const rawDate = {
      hours: time.hours < 10 ? `0${time.hours}` : time.hours,
      minutes: time.minutes < 10 ? `0${time.minutes}` : time.minutes,
      seconds: time.seconds < 10 ? `0${time.seconds}` : time.seconds,
    };

    return `${rawDate?.hours}:${rawDate?.minutes}:${rawDate?.seconds}`;
  };

  const _getDuration = () => {
    const start = onParseDateISO(startDate);

    const end = onParseDateISO(endDate);

    return intervalToDuration({ start, end });
  };

  const getRangeBetweenDates = () => {
    const time = _getDuration();

    return convertDurationToTime(time);
  };

  const withInfinity = (startDate, endDate, join: string | object = '') => {
    if (!startDate && !endDate) {
      return combineInfinities ? <Icon name="Infinity" /> : null;
    }

    if (!startDate && endDate) {
      return (
        <>
          <Icon name="Infinity" />
          {join}
          {endDate}
        </>
      );
    }

    if (startDate && !endDate) {
      return (
        <>
          {startDate}
          {join}
          <Icon name="Infinity" />
        </>
      );
    }

    if (startDate && endDate) {
      return typeof join === 'string' ? (
        [startDate, endDate].join(join)
      ) : (
        <>
          {startDate}
          {join}
          {endDate}
        </>
      );
    }
  };

  const getSecondToFormat = () => {
    if (!secondTime) return null;

    const hours = secondsToHours(+secondTime);

    const minutes = secondsToMinutes(+secondTime);

    const rawDate = {
      hours: hours < 10 ? `0${hours}` : hours,
      minutes: minutes < 10 ? `0${minutes}` : minutes > 60 ? 60 : minutes,
      seconds:
        secondTime < 10
          ? `0${secondTime}`
          : secondTime > 60
          ? `${+secondTime % 60 < 10 ? `0${+secondTime % 60}` : `${+secondTime % 60}`}`
          : secondTime === 60
          ? '00'
          : secondTime,
    };

    return `${rawDate?.hours}:${rawDate?.minutes}:${rawDate?.seconds}`;
  };

  const renderCombineInfinities = (action) => {
    const isValidDate = !!(startDate || endDate || date);

    if (!isValidDate) {
      return combineInfinities ? children(action) : null;
    }

    return children(action);
  };

  const renderContent_30_Days = () => {
    const start = onParseDateISO(startDate);

    const end = Date.now();

    const time = differenceInMinutes(end, start);

    return time < 30;
  };

  const onDifferenceInBusinessDaysNow = () => {
    const endDateISO = onParseDateISO(endDate);

    const startDateISO = typeof startDate === 'string' ? onParseDateISO(startDate) : startDate;

    return differenceInCalendarDays(endDateISO, startDateISO);
  };

  return renderCombineInfinities({
    onParseDateISO,
    onParseDateFormatISO,
    onParseDateFormatAgo,
    getStartDate,
    getEndDate,
    getRangeBetweenDates,
    withInfinity,
    getSecondToFormat,
    onDifferenceInBusinessDays,
    renderContent_30_Days,
    onDifferenceInBusinessDaysNow,
  });
};

const mapStateToProps = (store: IReduxStore) => ({
  user: store.middleware.user!,
});

export default connect(mapStateToProps)(LocationDate);
