import get from 'lodash/get';
import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useGetParams, useRouter, useSetParams, useToState } from '../../../hooks';
import translate from '../../../i18n/translate';
import { getRoute } from '../../../routes/client';
import { URI } from '../../../routes/uri';
import { uuid } from '../../../utils';
import { setAlert } from '../../app.actions';
import { Icon } from '../../app.modules';
import { MODALS_TRIGGER } from '../../Modal/modal.types';
import { IEducationsFrom, IEducationsGetTest, IEducationsTo, ITest } from '../tests.resources';
import { getTests, testCreate, testDelete } from '../tests.services';

interface IProps {
  getTestsAction(data: IEducationsTo | any): Promise<IEducationsFrom>;
  testDeleteAction(data: number): Promise<ITest>;
  setAlertAction(data: object): void;
  children: any;
  typeLoad?: string;
  status?: string;
  testCreateAction(data: IEducationsGetTest): Promise<IEducationsGetTest>;
}

interface IState {
  tests: IEducationsFrom;
  isLoading: boolean;
  pagination: IEducationsTo;
  test: IEducationsGetTest | null;
  testDelete: boolean;
  lastItem: any;
  next: boolean;
}

const TestsService: FC<IProps> = ({
  getTestsAction,
  testDeleteAction,
  children,
  setAlertAction,
  status,
  testCreateAction,
}): any => {
  const { history } = useRouter();

  const [state, setState] = useToState<IState>({
    tests: {
      data: [],
      total: 1,
    },
    pagination: {
      page: 1,
      perPage: 5,
      status,
      sortBy: 'createdAt',
      sortOrder: 'DESC',
    },
    isLoading: true,
    test: null,
    testDelete: false,
    lastItem: null,
    next: true,
  });

  const pageUpdate = (pagination, callback: any = null) => {
    const paramsQuery = {
      ...pagination,
      ...(status && { status }),
    };

    getTestsAction(paramsQuery).then((response) => {
      const data = get(response, 'data', []);

      const total = get(response, 'total', 0);

      setState((state) => ({
        ...state,
        isLoading: false,
        tests: {
          data: data,
          total,
        },
        next: data.length !== 0,
      }));

      if (callback) {
        callback(response);

        const element = document.querySelector('.tests-events__content');

        element?.scrollIntoView({
          block: 'start',
          behavior: 'auto',
        });
      }
    });
  };

  const onReset = () => {
    setState({
      tests: {
        data: [],
        total: 1,
      },
      pagination: {
        page: 1,
        perPage: 5,
        status,
        sortBy: 'createdAt',
        sortOrder: 'DESC',
      },
      next: true,
    });
  };

  useEffect(() => {
    const { pagination } = state;

    onReset();
    pageUpdate(pagination);

    return onReset;
  }, []);

  const toggleModal = (type, data: any = null) => {
    setState((state) => ({
      ...state,
      [type]: !state[type],
      test: data,
    }));
  };

  const deleteTest = (testId) => {
    return new Promise((resolve) => {
      testDeleteAction(testId)
        .then((response) => {
          const filtered = [...state.tests.data].filter((el) => el.id !== testId);

          setState((state) => ({
            ...state,
            tests: {
              ...state.tests,
              data: filtered,
            },
            lastItem: { current: response },
          }));
          resolve(response);
        })
        .catch(() => {
          setAlertAction({
            code: '089999',
            uuid: uuid(),
            params: {},
          });
        });
    });
  };

  const onChangePagination = (page, perPage) => {
    return new Promise((resolve) => {
      pageUpdate(
        {
          page,
          perPage,
        },
        resolve
      );
    });
  };

  const onSearch = (value) => {
    const { pagination } = state;

    return new Promise((resolve) => {
      const filter = { ...pagination, 'filter[name]': value };

      getTestsAction(filter).then((response) => {
        const data = get(response, 'data', []);

        const total = get(response, 'total', 0);

        setState((state) => ({
          ...state,
          isLoading: false,
          tests: {
            data: data,
            total,
          },
          pagination: filter,
        }));
        resolve(response);
      });
    });
  };

  const onChangeSort = (sort) => {
    const { pagination } = state;

    const paginationUpdate = {
      ...pagination,
      ...sort,
    };

    getTestsAction(paginationUpdate).then((response) => {
      const data = get(response, 'data', []);

      const total = get(response, 'total', 0);

      setState((state) => ({
        ...state,
        isLoading: false,
        tests: {
          data: data,
          total,
        },
        pagination: paginationUpdate,
      }));
    });
  };

  const onCreateNewTest = () => {
    history.push(
      useSetParams({
        m: MODALS_TRIGGER.TESTS.CREATE,
      })
    );
  };

  const paramsPart = useGetParams('part');

  const onConfirmChangeTest = (value) => {
    history.push(
      useSetParams({
        m: MODALS_TRIGGER.STRUCTURE.TESTS_ATTACH,
        part: paramsPart,
        testId: value.id,
      })
    );
  };

  const onToTest = (testId) => {
    history.push(getRoute('AP/T/-/S', 'path', { full: true })(testId));
  };

  const renderDataCard = () => {
    const { data, total } = state.tests;

    return {
      column: data.map((el) => ({
        ...el,
        attach: {
          title: (
            <>
              {translate('ts.add.event')} ({el?.eventsInfo?.length})
            </>
          ),
          data: el?.eventsInfo,
        },
        _onClick: onConfirmChangeTest,
        miniCoverFileId: () => ({
          // value: el?.miniCoverFileId && el.id,
          value: el?.miniCoverFileId ? URI.TESTS.ADMIN_LIST_MINI_COVER(el.id) : null,
          title: '',
          position: 1,
        }),
        name: () => ({
          value: { name: el?.name, description: el?.description },
          title: translate('g.name'),
          sort: onChangeSort,
          position: 2,
        }),
        testInfo: () => ({
          value: {
            questions: el.questionsAmount,
            attemptTime: el.attemptTime,
            maxScore: el.maxScore,
            onClick: onToTest,
          },
          title: '',
          position: 3,
        }),
        updatedAt: () => ({
          value: el?.createdAt,
          title: translate('g.createdAt'),
          sort: onChangeSort,
          position: 4,
        }),
      })),
      thead: {
        params: {
          size: 'sm',
          sticky: false,
        },
      },
      startPanel: {
        search: {
          handler: onSearch,
          placeholder: translate('ts.crt.name.pls'),
        },
        link: <strong onClick={onCreateNewTest}>{translate('events.str.test.lbl')}</strong>,
        params: {
          size: 'sm',
        },
      },
      endPanel: {
        pagination: {
          data: { ...state.pagination, total },
          onChange: onChangePagination,
        },
      },
    };
  };

  const onToBuildTest = (data) => {
    history.push({
      pathname: getRoute('BQ/-/', 'path', { full: true })(data?.id),
      state: {
        education: {
          ...data,
        },
      },
    });
  };

  const renderDataCardList = () => {
    const { data, total } = state.tests;

    const formationCard = {
      column: data.map((el) => ({
        ...el,
        attach: {
          title: (
            <>
              {translate('ts.add.event')} ({el?.eventsInfo?.length})
            </>
          ),
          data: el?.eventsInfo,
        },
        _onClick: (data) => onToTest(data?.id),
        params: {
          element: el,
        },
        miniCoverFileId: () => ({
          // value: el?.miniCoverFileId && el.id,
          value: el?.miniCoverFileId ? URI.TESTS.ADMIN_LIST_MINI_COVER(el.id) : null,
          title: '',
          position: 1,
        }),
        name: () => ({
          value: { name: el?.name, description: el?.description },
          title: translate('g.name'),
          sort: onChangeSort,
          position: 2,
        }),
        questions: () => ({
          value: {
            countQuestions: el.questionsAmount,
            onClick: onToBuildTest,
            hint: translate('ts.change.qs'),
          },
          title: translate('g.questions.s'),
          sort: onChangeSort,
          position: 3,
        }),
        maxScore: () => ({
          value: el?.maxScore,
          title: translate('g.point.a'),
          sort: onChangeSort,
          position: 4,
        }),
        updatedAt: () => ({
          value: el?.createdAt,
          title: translate('g.createdAt'),
          sort: onChangeSort,
          position: 5,
        }),
      })),
      startPanel: {
        search: {
          handler: onSearch,
          placeholder: translate('ts.crt.name.pls'),
        },
        button: {
          title: (
            <>
              <Icon name="ELPlusFill" />
              {translate('g.btn.create')}
            </>
          ),
          handler: onCreateNewTest,
          size: 'md',
          restrictions: ['tests', 'count', state.tests?.total],
          permissions: ['create', 'tests'],
        },
      },
      endPanel: {
        pagination: {
          data: { ...state.pagination, total },
          onChange: onChangePagination,
        },
      },
    };

    return formationCard;
  };

  return children({
    state,
    deleteTest,
    toggleModal,
    onNewCreateTest: testCreateAction,
    onAlert: setAlertAction,
    onChangePagination,
    onSearch,
    onChangeSort,
    dataCard: renderDataCard(),
    dataCardList: renderDataCardList(),
  });
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getTestsAction: bindActionCreators(getTests, dispatch),
  testDeleteAction: bindActionCreators(testDelete, dispatch),
  setAlertAction: bindActionCreators(setAlert, dispatch),
  testCreateAction: bindActionCreators(testCreate, dispatch),
});

export default connect(null, mapDispatchToProps)(TestsService);
